<template>
  <div class="container-fluid">
    <div class="row text-center">
      <div class="col">
        <div class="h1 font-font-weight-bold">
          Relatórios
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 d-flex justify-content-around">
        <div class="p-3">
          <h3>Treinamentos</h3>
            <div class="ml-3">
              <router-link to="/admin/relatorios/treinamento">
                <p class="font-weight-bold mhand font-size-12 text-black-50">
                  <i class="fas fa-angle-double-right mr-2"></i>
                  Treinamentos
                </p>
              </router-link>
              <router-link to="/admin/relatorios/treinamento/faltas">
                <p class="font-weight-bold mhand font-size-12 text-black-50">
                  <i class="fas fa-angle-double-right mr-2"></i>
                  Faltas
                </p>
              </router-link>
              <router-link to="/admin/relatorios/treinamento/vencidos">
                <p class="font-weight-bold mhand font-size-12 text-black-50">
                  <i class="fas fa-angle-double-right mr-2"></i>
                  Treinamentos em vencimento
                </p>
              </router-link>
              <router-link to="/admin/relatorios/funcionarios/credencial">
                <p class="font-weight-bold mhand font-size-12 text-black-50">
                  <i class="fas fa-angle-double-right mr-2"></i>
                  Credenciais de funcionários
                </p>
              </router-link>
              <router-link to="/admin/relatorios/empresa-unidade/documentos">
                <p class="font-weight-bold mhand font-size-12 text-black-50">
                  <i class="fas fa-angle-double-right mr-2"></i>
                  Documentos da empresa
                </p>
              </router-link>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.font-size-12 {
  font-size: 1.2rem;
}
</style>